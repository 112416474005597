import React from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES, BODY_TYPES } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';
import { styleObject } from 'Style/type-helpers';

import Config from 'Config';
import {
  isOwnedSectionPath,
  getCurrentDomainFlipboardUrl,
} from 'Utils/content/flipboard-urls';
import { stripParams } from 'Utils/url';
import { WidgetLayout } from 'Webapp/enums';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';
import CustomRadio from 'ComponentLibrary/forms/custom-radio';
import Button from 'Webapp/shared/app/components/button';
import TooltipMenu from 'ComponentLibrary/menus/tooltip-menu.js';
import Input from 'Webapp/shared/app/components/base/input';

import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import withT from 'ComponentLibrary/hocs/withT';
import { WIDGET_MAGAZINE_OR_STORYBOARD_REGEXP } from 'Webapp/utils/reg-exp';

const iframeDimensions = {
  [WidgetLayout.THUMBNAIL]: {
    width: 250,
    height: 300,
  },
  [WidgetLayout.PORTRAIT]: {
    width: 300,
    height: 500,
  },
  [WidgetLayout.BANNER]: {
    width: 970,
    height: 250,
  },
};

const WidgetTool = styled.div(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    gridTemplateAreas:
      '"title title" "subText layout" "code code" "preview preview"',
    gap: SPACING.XLARGE,
  },
  styleObject(
    BREAKPOINTS.tabletLandscapeDown({
      gridTemplateAreas:
        '"title title" "subText subText" "layout layout" "code code" "preview preview"',
    }),
  ),
);

const Title = styled.h2(UI_TEXT_TYPES.TITLE, {
  gridColumn: 'span 2',
  gridArea: 'title',
  color: 'inherit',
});
const StepTitle = styled.h3(UI_TEXT_TYPES.TITLE_SMALL, {
  color: 'inherit',
  marginBottom: SPACING.LARGE,
});
const SubText = styled.div({
  gridColumn: 'span 2',
  gridArea: 'subText',
  color: 'inherit',
});

const ErrorMessage = styled.p({
  marginTop: SPACING.MEDIUM,
  color: COLORS.red,
});

const FieldLabel = styled.h3(UI_TEXT_TYPES.TITLE_SMALL, {
  marginBottom: SPACING.LARGE,
});

const LayoutSelection = styled.div({ gridArea: 'layout' });

const WidgetCode = styled.div({
  gridArea: 'code',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: '36px 1fr',
});

const WidgetCodeSnippet = styled.textarea(
  {
    gridColumn: 'span 2',
    height: '150px',
    resize: 'none',
  },
  BODY_TYPES.XSMALL_STANDARD,
);

const WidgetPreview = styled.div({
  gridArea: 'preview',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  alignItems: 'center',
  justifyItems: 'center',
});

const CodePasteContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const CopyButton = styled(Button)({
  height: '50%',
  padding: '0',
});

const PreviewPrivacyMessage = styled.p({
  marginBottom: SPACING.LARGE,
});

type MagazineAndStoryboardWidgetToolProps = {
  className: string;
  section: Flipboard.Section;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps;

type MagazineAndStoryboardWidgetToolState = {
  loadingSpinner: boolean;
  layout: WidgetLayout;
  displayCopyMessage: boolean;
  sourceUrl: string | null;
  isError: boolean;
};

const DEFAULT_LAYOUT = WidgetLayout.THUMBNAIL;

class MagazineAndStoryboardWidgetTool extends React.Component<
  MagazineAndStoryboardWidgetToolProps,
  MagazineAndStoryboardWidgetToolState
> {
  state = {
    loadingSpinner: false,
    layout: DEFAULT_LAYOUT,
    displayCopyMessage: false,
    sourceUrl: this.props.section?.sourceURL || null,
    isError: false,
  };

  componentDidUpdate(
    _prevProps: MagazineAndStoryboardWidgetToolProps,
    prevState: MagazineAndStoryboardWidgetToolState,
  ) {
    if (
      prevState.layout !== this.state.layout ||
      prevState.sourceUrl !== this.state.sourceUrl
    ) {
      this.setState({ displayCopyMessage: false });
    }
  }

  verifyCorrectUrl = (url: string) =>
    WIDGET_MAGAZINE_OR_STORYBOARD_REGEXP.test(url);

  onChangeLayout = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ layout: e.target.value as WidgetLayout });
  };

  onChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const url = e.target.value.trim();
    // must be a flipboard URL
    const isFlipboardUrl = this.verifyCorrectUrl(url);
    if (!isFlipboardUrl) {
      this.setState({ sourceUrl: url, isError: true });
      return;
    }

    const path = url.replace(Config.FLIPBOARD_TOP_DOMAIN_URL, '');
    if (!isOwnedSectionPath(path)) {
      this.setState({ sourceUrl: url, isError: true });
      return;
    }
    this.setState({ sourceUrl: url, isError: false, loadingSpinner: true });
  };

  handleFocusSnippet = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { isError, sourceUrl } = this.state;

    if (isError || !sourceUrl) {
      return;
    }

    const codeSnippet = this.getSnippetCode();
    navigator.clipboard.writeText(codeSnippet);
    this.setState({ displayCopyMessage: true });
  };

  getSnippetCode = () => {
    const { layout, sourceUrl } = this.state;
    const widgetSourceUrl =
      sourceUrl && `${stripParams(sourceUrl)}/widget?layout=${layout}`;

    return `<iframe
      src="${widgetSourceUrl}"
      frameBorder="0"
      width="${iframeDimensions[layout].width}"
      height="${iframeDimensions[layout].height}"
    ></iframe>`;
  };

  renderWidgetPreviewIFrame = () => {
    const { sourceUrl, layout } = this.state;
    const widgetSourceUrl =
      sourceUrl && `${stripParams(sourceUrl)}/widget?layout=${layout}`;
    return (
      widgetSourceUrl && (
        <iframe
          src={getCurrentDomainFlipboardUrl(widgetSourceUrl)}
          width={iframeDimensions[layout].width}
          height={iframeDimensions[layout].height}
          onLoad={() => this.setState({ loadingSpinner: false })}
        />
      )
    );
  };

  render() {
    const { className, t } = this.props;
    const { loadingSpinner, layout, sourceUrl, isError } = this.state;

    return (
      <WidgetTool className={className}>
        <Title>{t('embed_tool_title')}</Title>

        <SubText>
          <FieldLabel>1. {t('embed_tool_url_input_label')}:</FieldLabel>
          <Input
            placeholder="https://flipboard.com/@flipboard/-magswelove-9dnecbitz"
            value={sourceUrl || ''}
            onChange={this.onChangeUrl}
          />
          {isError && (
            <ErrorMessage>
              {t('embed_tool_url_error_message_storyboard_magazine')}
            </ErrorMessage>
          )}
        </SubText>
        <>
          <LayoutSelection>
            <StepTitle>2. {t('embed_tool_pick_style')}</StepTitle>
            <ul>
              {Object.values(WidgetLayout).map((option) => (
                <li key={option} className="embed-tool__layout-option">
                  <CustomRadio
                    name="layoutPicker"
                    label={t(`embed_tool_${option.toLowerCase()}_size`)}
                    value={option}
                    checked={layout === option}
                    onChange={this.onChangeLayout}
                  />
                </li>
              ))}
            </ul>
          </LayoutSelection>
          <WidgetCode>
            <CodePasteContainer>
              <StepTitle>3. {t('embed_tool_snippet_label')}</StepTitle>
              {!isError && sourceUrl && (
                <TooltipMenu
                  toggleElement={
                    <CopyButton onClick={this.handleFocusSnippet}>
                      {t('copy')}
                    </CopyButton>
                  }
                >
                  {t('copied')}
                </TooltipMenu>
              )}
            </CodePasteContainer>
            <WidgetCodeSnippet
              readOnly
              value={isError || !sourceUrl ? '' : this.getSnippetCode()}
              placeholder={t('embed_enter_url_placeholder')}
            />
          </WidgetCode>
          <WidgetPreview>
            <StepTitle>{t('embed_tool_preview_label')}</StepTitle>
            <PreviewPrivacyMessage>
              {t('embed_tool_preview_privacy_message_warning')}
            </PreviewPrivacyMessage>
            {loadingSpinner && !isError && <LoadingSpinner />}
            {!isError && this.renderWidgetPreviewIFrame()}
          </WidgetPreview>
        </>
      </WidgetTool>
    );
  }
}
export default connector(connectFeatureFlags)(
  withT(MagazineAndStoryboardWidgetTool),
);
